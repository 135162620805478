import ReactSlider from "react-slider";

const updateSlider = (sliderVal, childToParentDurationTime, childToParentDotTime) => {
    if (childToParentDurationTime !== undefined) childToParentDurationTime(sliderVal);
    else if (childToParentDotTime !== undefined ) childToParentDotTime(sliderVal);
  }

const Slider = ({ childToParentDotTime, childToParentDurationTime, minVal, maxVal, currentIndex, sliderType }) => {
  return (
    <ReactSlider
      className={sliderType}
      markClassName="example-mark"
      thumbClassName="example-thumb"
      trackClassName="example-track"
      marks
      min={minVal}
      max={maxVal}
      renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
      onAfterChange={(sliderVal) => updateSlider(sliderVal, childToParentDotTime, childToParentDurationTime)}
      value={currentIndex}
    />
  );
};

export { Slider };