import React from 'react'
import { useRef, useState, useCallback, useEffect } from 'react'
import Webcam from 'react-webcam'
import moment from 'moment'

import MovingDot from './Moving_dot.js'


const VideosCapture = ({fullscreenHandle, dotAppearances, durationDot, patternType, grid, ID}) => {

  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [timeFinished, setTimeFinished] = useState("");
  const [recordedChunks, setRecordedChunks] = useState([]);
  const videoConstraints = {facingMode: "user"}
  const videoTime = [];
  const videoData = {};
  if (ID === '') {ID = '0000'}


  const handleStartCaptureClick = useCallback(() => {
    setRecordedChunks([]);
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm"
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
    videoData.vidStart_ms = moment().valueOf();
    // eslint-disable-next-line
  }, [webcamRef, mediaRecorderRef]);

  
  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );


  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    videoData.vidStop_ms = moment().valueOf();
    videoTime.push(videoData)
    appendDownloadNode(videoTime)
    videoTime.length = 0;
    setCapturing(false);
    // eslint-disable-next-line
  }, [mediaRecorderRef, webcamRef]);


  const handleDownload = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm"
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      // eslint-disable-next-line
      a.download = ID + "_"+ "UserWebcam_" + timeFinished + ".webm";
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setRecordedChunks([]);
    }
    // eslint-disable-next-line
  }, [recordedChunks]);


  const appendDownloadNode = (vidMeta) => {
    const data = JSON.stringify(vidMeta)
    const blob = new Blob([data],{type:'application/json'});
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = "videoMetadata.json" ; // probably redundant
    link.id = "videoMetadata"
    document.body.appendChild(link);
    }


  const downloadMetadata = (metaType) => {
    let element = document.getElementById(metaType)
    element.download = ID + "_" + metaType + "_" + timeFinished + ".json"
    element.click();
    window.URL.revokeObjectURL(element.href);
    document.body.removeChild(element);
  }


  const purgeMetaElements = () => {
    const elementMetaScreen = document.getElementById("screenMetadata")
    const elementMetaVideo = document.getElementById("videoMetadata")


    if (elementMetaScreen != null & elementMetaVideo != null)
    {
      window.URL.revokeObjectURL(elementMetaScreen.href);
      document.body.removeChild(elementMetaScreen);

      window.URL.revokeObjectURL(elementMetaVideo.href);
      document.body.removeChild(elementMetaVideo);
    }
  }


  const dotPatternFinished = () => {
    setTimeFinished(moment().format("DD_MM_HH_mm_ss"))
    handleStopCaptureClick();
  }

  const startWrapper = () => {
    if (fullscreenHandle.active){
    purgeMetaElements();
    handleStartCaptureClick();
    }
    else{
      alert("Please go into the full screen mode");
    }
  }

  // Ensure capturing state change to false when fullscreen variable changes.
  useEffect(() => {
    setCapturing(false)
    //setRecordedChunks([])
  }, [fullscreenHandle.active])



    return (

      <div className="VideoButtons">
          {capturing && fullscreenHandle.active ?
          ( <div>
              <MovingDot pattern={patternType} grid={grid} dotAppearances={dotAppearances} durationDot={durationDot} onScanFinish = {dotPatternFinished} />
            </div>):
          (
            <div>
              <button className="button" onClick={()=>{ startWrapper() }}>Start Capture</button>
              {/*<button className="button" style={{color: '#959595'}}
               onClick={ () => { childFuncSetAcceptCfg() ; purgeMetaElements(); setRecordedChunks([]) } }> Change Pattern Configuration </button>*/}
              <button className="button" style={{color: '#959595'}}
               onClick={ () => window.location.reload(false) }> Change Pattern Configuration </button>
            </div>
          )}

          {recordedChunks.length > 0 & !capturing && (
            <div>
              <button className="button" onClick={()=>{
                downloadMetadata("screenMetadata");
                downloadMetadata("videoMetadata");
                handleDownload()}}>Download Generated Metadata</button>
            </div>
          )}
          <Webcam audio={false} ref={webcamRef} style={{width: "0%",
          height: "0%", color: "black"}} videoConstraints={videoConstraints}/>
      </div>

    );
  };

  export default VideosCapture