import './App.css';
import { useState } from 'react'
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { Slider } from './Slider'
import VideosCapture from './Video_captures.js'


function App() {

  const fullscreenHandle = useFullScreenHandle();

  const [acceptConfig, setAcceptConfig] = useState(false)
  const [dotCount, setDotCount] = useState({val: 4, minVal: 4, maxVal: 81});
  const [dotTime, setDotTime] = useState({val: 1, minVal: 1, maxVal: 5});

  const [calibType, setCalibType] = useState(Number(0));

  const [gridRows, setgridRows] = useState(2);
  const [gridCols, setgridCols] = useState(2);

  const [userID, setUserID] = useState("");


  const childToParentDuration = (childData) => {
    let updatedValue = {};
    updatedValue = {val : childData};
    setDotCount(dotCount => ({
          ...dotCount,
          ...updatedValue
        }));
  }

  const childToParentDotTime = (childData) => {
    let updatedValue = {};
    updatedValue = {val : childData};
    setDotTime(dotTime => ({
          ...dotTime,
          ...updatedValue
        }));
  }

  const onNext = (sliderType) => {
    if (sliderType === 'duration'){
      let updatedValue = {};
      if  (dotCount.val < dotCount.maxVal){
      updatedValue = {val : dotCount.val + 1};
      setDotCount(dotCount => ({
            ...dotCount,
            ...updatedValue
          }));
        }
    }
    else if (sliderType === 'dotTime'){
      let updatedValue = {};
      if  (dotTime.val < dotTime.maxVal){
      updatedValue = {val : dotTime.val + 1};
      setDotTime(dotTime => ({
            ...dotTime,
            ...updatedValue
          }));
        }
    }
  };


  const onPrevious = (sliderType) => {
    if (sliderType === 'duration'){
      let updatedValue = {};
      if  (dotCount.val > dotCount.minVal){
      updatedValue = {val : dotCount.val - 1};
      setDotCount(dotCount => ({
            ...dotCount,
            ...updatedValue
          }));
        }
    }
    else if (sliderType === 'dotTime'){
      let updatedValue = {};
      if  (dotTime.val > dotTime.minVal){
      updatedValue = {val : dotTime.val - 1};
      setDotTime(dotTime => ({
            ...dotTime,
            ...updatedValue
          }));
        }
    }
  };

  const changeRows = (operation) =>{
    // Tune dot appearances count in grid mode on change rows button clicks
    let updatedValue = {};

    if (operation === 'increase' && gridRows < 9)
    {
      setgridRows(gridRows + 1)
      updatedValue = {val : (gridRows+1) * gridCols};
    }
    else if (operation === 'decrease' && gridRows > 2)
    {
      setgridRows(gridRows - 1)
      updatedValue = {val : (gridRows-1) * gridCols};
    }
    setDotCount(dotCount => ({
      ...dotCount,
      ...updatedValue
    }));

  }

  const changeCols = (operation) =>{
    // Tune dot appearances count in grid mode on change columns button clicks
    let updatedValue = {};

    if (operation === 'increase' && gridCols < 9)
    {
      setgridCols(gridCols + 1)
      updatedValue = {val : gridRows * (gridCols+1)};

    }
    else if (operation === 'decrease' && gridCols > 2)
    {
      setgridCols(gridCols - 1)
      updatedValue = {val : gridRows * (gridCols-1)};
    }
    setDotCount(dotCount => ({
      ...dotCount,
      ...updatedValue
    }));
  }

  const handleRadioClick = (patType) =>
  {
    // Make 5/9/13 point calibration patterns change dot appearences to relatable values
    if (patType === 1 || patType === 2 || patType === 3)
    {
      let updatedValue = {};
      if (patType === 1) updatedValue = {val : 5};
      else if (patType === 2) updatedValue = {val : 9};
      else if (patType === 3) updatedValue = {val : 13};
      setDotCount(dotCount => ({
        ...dotCount,
        ...updatedValue
      }));
    }
  }

  const onIDChange = (e) => {
    // Allow user to enter digits only
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
       setUserID(e.target.value)
    }
}

  const hideKeyboard = (e) =>  {
    // Made for mobile devices - so that the keyboard layout hides when user instinctively
    // presses enter symbol to accept value he entered - need for that was empirically tested
    if(e.key === 'Enter') {
      e.target.blur();
    }
  }

  return (
    <div className="App">
        {!acceptConfig && (
        <div>
            <h2 className="BlueCaption">Select dot appearance count </h2>
            <Slider childToParentDurationTime={childToParentDuration} minVal={dotCount.minVal} maxVal={dotCount.maxVal} currentIndex={dotCount.val} sliderType="horizontal-sliderTime"/>
            <div>
              <button className="buttonIncreaseSlider" onClick = {() => { onNext('duration') }} > + </button>
              <button className="buttonDecreaseSlider" onClick = {() => { onPrevious('duration') }} > - </button>
            </div>
            <h2 className="BlueCaption">Select dot screen time </h2>
            <Slider childToParentDotTime={childToParentDotTime} minVal={dotTime.minVal} maxVal={dotTime.maxVal} currentIndex={dotTime.val} sliderType="horizontal-sliderDot"/>
            <div>
              <button className="buttonIncreaseSlider" onClick = {() => { onNext('dotTime') }} > + </button>
              <button className="buttonDecreaseSlider" onClick = {() => { onPrevious('dotTime') }} > - </button>
            </div>
            <h2 className='BlueCaption'>Select Calibration Pattern</h2>
            <div className="WhiteCaption" onChange={ (e) => { setCalibType(Number(e.target.value)) ; handleRadioClick(Number(e.target.value)) } }>
              <input type="radio" value={0} name="radioGroup"/> Random
              <input type="radio" value={1} name="radioGroup"/> 5-Point Calibration
              <input type="radio" value={2} name="radioGroup"/> 9-Point Calibration
              <input type="radio" value={3} name="radioGroup"/> 13-Point Calibration
              <input type="radio" value={4} name="radioGroup"/> Grid
              {calibType === 4 && (
                  <div>
                    <h2 className="BlueCaption" style={{marginTop: "20px"}}>Select Grid Size (rows x cols)</h2>
                    <h2>{gridRows} x {gridCols}</h2>
                    <button className="buttonIncreaseGrid" onClick = {() => { changeRows('increase')}} > + </button>
                    <button className="buttonDecreaseGrid" onClick = {() => { changeRows('decrease') }} > - </button>
                    <button className="buttonIncreaseGrid" onClick = {() => { changeCols('increase')}} > + </button>
                    <button className="buttonDecreaseGrid" onClick = {() => { changeCols('decrease') }} > - </button>
                  </div>
              )}
            </div>
            <h2 className='BlueCaption' style={{marginTop: "25px"}} >Write down user's ID</h2>
            <input className='idInput' value={userID} placeholder='Digits only' onChange={(e) => onIDChange(e)} onKeyUp= {(e) => hideKeyboard(e)} />
            <button className="button" onClick={ () => setAcceptConfig(true) }> Accept </button>
          </div>)
        }

        {acceptConfig && (
          <div>
          <button className="button" onClick={ () => { fullscreenHandle.enter() }}>Enter fullscreen</button>
          <FullScreen handle={fullscreenHandle} >
            <VideosCapture fullscreenHandle = {fullscreenHandle} dotAppearances={dotCount.val}
             durationDot={dotTime.val} patternType={calibType} grid={[gridRows, gridCols]} ID={''+userID} cfgAccept={acceptConfig}/>
          </FullScreen>
        </div>)
        }
    </div>
  );
}

export default App;