import React, { Component } from 'react'
import moment from 'moment';

class MovingDot extends Component {
  constructor(props){
    super(props)

    this.state = {
      date: 0,
      dot_X: getComputedStyle(document.documentElement)
      .getPropertyValue('--left-pos'),
      dot_Y:  getComputedStyle(document.documentElement)
      .getPropertyValue('--top-pos'),
      deviceHeight: window.innerHeight,
      deviceWidth: window.innerWidth,
    }

    this.patternType = props.pattern
    this.dotAppearances = props.dotAppearances
    this.durationOfDot = props.durationDot
    this.grid = props.grid

    this.jsonfile = []
    this.exclusionArray = []
    this.circle_diameter = 20
    this.counter = 0

    this.showInfo(this.patternType)
  }

  showInfo(type)
  {
    if (type === 0) {this.typeInfo = "Random"}
    else if (type === 1) {this.typeInfo = "5 Point Calibration"}
    else if (type === 2) {this.typeInfo = "9 Point Calibration"}
    else if (type === 3) {this.typeInfo = "13 Point Calibration"}
    else if (type === 4) {this.typeInfo = "User-defined Grid"}
  }

  randomizeCnt(orgIdx, resetVal){
    // Maximum idx equals to resetVal = rows*cols
    orgIdx = orgIdx % resetVal

    let selected = false
    let randomIdx = null

    while(!selected)
    {
      randomIdx = Math.floor(Math.random() * resetVal)
      if (!(this.exclusionArray.includes(randomIdx)))
      {
        this.exclusionArray.push(randomIdx)
        selected = true
      }
    }

    if (orgIdx === resetVal-1)
    {
      // If original index going from 0 to resetVal reaches upper limit make all fields avaiable again
      this.exclusionArray.length = 0
    }

    return randomIdx
  }


  calibrationPattern(cnt) {
    // This is generated over and over again at fixed intervals to make the pattern adaptable to different window sizes
    // (probably redundant in our use case and could just be moved to constructor but I'm leaving it this way)
    this.setState({deviceWidth: window.innerWidth});
    this.setState({deviceHeight: window.innerHeight});

    let start_width = this.state.deviceWidth
    let start_height  = this.state.deviceHeight
    let new_x = 0;
    let new_y = 0;

    /// For 5 Point Calib ///
    let upperLeftCorner = [0, 0]
    let upperRightCorner = [start_width - this.circle_diameter, 0]
    let bottomLeftCorner = [0, start_height - this.circle_diameter]
    let bottomRightCorner = [start_width - this.circle_diameter, start_height - this.circle_diameter]
    let centerPoint = [(start_width/2)-this.circle_diameter/2, (start_height/2)-this.circle_diameter/2]
    let calibArray5 = [upperLeftCorner, upperRightCorner, centerPoint, bottomLeftCorner, bottomRightCorner]

    /// For 9 Point Calib ///
    let centerToUpperLeft = [start_width/4 - this.circle_diameter/2, start_height/4- this.circle_diameter/2]
    let centerToUpperRight = [start_width/2 + start_width/4- this.circle_diameter/2, start_height/4- this.circle_diameter/2]
    let centertoBottomLeft = [start_width/4- this.circle_diameter/2, start_height/2 + start_height/4- this.circle_diameter/2]
    let centertoBottomRight = [start_width/2 + start_width/4- this.circle_diameter/2, start_height/2 + start_height/4- this.circle_diameter/2]
    let calibArray9 = [upperLeftCorner, upperRightCorner, centerToUpperLeft, centerToUpperRight, centerPoint, centertoBottomLeft, centertoBottomRight, bottomLeftCorner, bottomRightCorner]

    /// For 13 Point Calib ///
    let centerUp = [centerPoint[0], 0]
    let centerLeft = [0, centerPoint[1]]
    let centerRight = [upperRightCorner[0], centerPoint[1]]
    let centerBottom = [centerPoint[0], bottomRightCorner[1]]
    let calibArray13 = [upperLeftCorner, centerUp, upperRightCorner,
      centerToUpperLeft, centerToUpperRight,
      centerLeft, centerPoint,  centerRight,
      centertoBottomLeft, centertoBottomRight,
      bottomLeftCorner, centerBottom, bottomRightCorner]


    switch(this.patternType) {
        case 0: // Random Places
          new_x = Math.floor(Math.random() * (start_width - this.circle_diameter));
          new_y = Math.floor(Math.random() * (start_height - this.circle_diameter));
          break;

        case 1: // 5 Point Calibration
          new_x = calibArray5[cnt%(calibArray5.length)][0]
          new_y = calibArray5[cnt%(calibArray5.length)][1]
          break;

        case 2: // 9 Point Calibration
          new_x = calibArray9[cnt%(calibArray9.length)][0]
          new_y = calibArray9[cnt%(calibArray9.length)][1]
          break;

        case 3: // 13 Point Calibration

          new_x = calibArray13[cnt%(calibArray13.length)][0]
          new_y = calibArray13[cnt%(calibArray13.length)][1]
        break;

        case 4: // User-selected grid
          let rows = this.grid[0]
          let cols = this.grid[1]
          let coordArray = new Array(rows*cols)
          let offset_x = start_width / cols
          let offset_y  = start_height / rows

          for (let i=0; i < rows; i++)
          {
            for (let j=0; j < cols; j++)
            {
              coordArray[i*cols + j] = new Array(2)
              coordArray[i*cols + j][0] = j * offset_x - (this.circle_diameter/2) + (offset_x/2)
              coordArray[i*cols + j][1] = i * offset_y - (this.circle_diameter/2) + (offset_y/2)
            }
          }

          let new_cnt = this.randomizeCnt(cnt, coordArray.length)
          new_x = coordArray[new_cnt][0]
          new_y = coordArray[new_cnt][1]

          break;

        default:
          new_x = Math.floor(Math.random() * (start_width - this.circle_diameter));
          new_y = Math.floor(Math.random() * (start_height - this.circle_diameter));
          break;

    }


    this.setState({
      date: moment().valueOf(),
      dot_X: (new_x).toString().concat('px'),
      dot_Y: (new_y).toString().concat('px')  }, () => {
      //callback  ----------- ! should lines 22&23 be moved here?
      this.pushToJson(this.state.date, this.state.dot_X, this.state.dot_Y, this.state.deviceWidth, this.state.deviceHeight)
      document.documentElement.style.setProperty('--left-pos', this.state.dot_X);
      document.documentElement.style.setProperty('--top-pos', this.state.dot_Y);
    });
    }


  pushToJson(timestamp, x, y, devX, devY){
    // image is rendered as a 20x20[px] rectangle with anchor set at upper-left, so the user sees the dot at [anchor_x+10, anchor_y+10] coordinates
    // so it is vital to recalculate CSS pixel values to user-seen values:
    x = Number(x.replace(/px/, '')) + this.circle_diameter/2;
    y = Number(y.replace(/px/, '')) + this.circle_diameter/2;
    let element = {}
    element.x = '' + x + 'px'
    element.y = '' + y + 'px'
    element.dev_w = devX
    element.dev_h = devY
    element.time_ms = timestamp
    this.jsonfile.push(element);
  }


  componentDidMount() {
    let small_counter = -2
    this.timerID = setInterval(() =>
    {
      if (small_counter >= 0 && small_counter % this.durationOfDot === 0)
      {
        this.calibrationPattern(this.counter)
        this.counter++

        if (this.counter > this.dotAppearances)
        {
          this.props.onScanFinish()
          return clearInterval(this.timerID);
        }
      }
      small_counter++
    }, 1000);
  };


  componentWillUnmount() {
    clearInterval(this.timerID);
    // Dont create a HTML element if full cycle was not completed - that would mean the recording was interrupted
    if (this.jsonfile.length === this.dotAppearances) 
    {
      this.appendDownloadNode()
    }
    
  }

  appendDownloadNode(){
    if (this.jsonfile.length > 0){
    const data = JSON.stringify(this.jsonfile)
    const blob = new Blob([data],{type:'application/json'});
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = "screenMetadata.json" ; // probably redundant
    link.id = "screenMetadata"
    document.body.appendChild(link);
    }
  }

  render(){

    return (
      <div>
        {this.counter > 0 ?(
          <div className="Dot">
            <img src={require("./circle20x20.png")} alt="" />
          </div>
        ):
        (
          <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
            <h1 className="RedCaption">A red dot is going to appear on the screen using {this.typeInfo} pattern.</h1>
          </div>
        )}
      </div>
    )
  }
}
  
export default MovingDot